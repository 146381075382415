<template>
  <v-container fluid id="frame-container" class="ma-0">
    <v-data-table
      :headers="headers"
      :items="cards.data"
      :loading="loading"
      :server-items-length="totalItems"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions}"
      class="elevation-1"
    >
      <template v-slot:item.tasks="{ item }" >
        <div v-for="task in item.tasks" :key="task.id">
          {{ task.name}}
        </div>
      </template>
      <template v-slot:item.cardType="{ item }" >
        {{ item.cardType.name }}
      </template>
      <template v-slot:item.status="{ item }" >
        {{ item.status ? 'Active' : 'Inactive' }}
      </template>
      <template v-slot:top>
        <search-bar @searchCard="filteredCards" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn :to="`/edit/${item.id}`" text fab>
          <v-icon md > mdi-circle-edit-outline </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getCards">Reset</v-btn>
      </template>
    </v-data-table>
    <div class="sub-nav-action text-center">
      <v-btn color="#2196f3" fab height="50px" width="50px" :to="{name: 'create-card'}">
        <v-icon color="white" size="24px">mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchBar from '../components/SearchBar';

export default {
  name: 'Home',
  components: {
    SearchBar,
  },
  data() {
    return {
      search: '',
      loading: false,
      itemsPerPageOptions: [5, 10, 15],
      totalItems: 0,
      options: {
        page: this.$route.query.page || 1,
        itemsPerPage: this.$route.query.size || 10,
      },
      headers: [
        {
          text: 'Name', align: 'start', value: 'name', sortable: false,
        },
        { text: 'Card Strategy', value: 'cardType', sortable: false },
        { text: 'Platform account', value: 'tenantUUID', sortable: false },
        { text: 'Validation pattern', value: 'validationPattern', sortable: false },
        { text: 'Tasks', value: 'tasks', sortable: false },
        { text: 'Channel', value: 'channel', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters(['cards']),
  },
  created() {
    // send initial parameters
    this.getCards({ page: 1, size: 10, search: '' });
  },
  watch: {
    options: {
      handler(newValue, oldValue) {
        if (newValue.page === oldValue.page && newValue.itemsPerPage === oldValue.itemsPerPage) {
          return;
        }
        this.loading = true;
        const payload = {
          page: newValue.page,
          size: newValue.itemsPerPage,
          search: this.search,
        };
        this.getCards(payload);
      },
      deep: true,
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }
        this.loading = true;
        const payload = {
          page: 1,
          size: this.options.itemsPerPage,
          search: this.search,
        };
        this.getCards(payload);
      },
      deep: true,
    },
  },
  methods: {
    getCards(payload) {
      this.$store.dispatch('loadCards', payload).then(() => {
        this.totalItems = this.cards.pagination.total;
        this.$router.push({ name: 'home', query: payload });
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    filteredCards(searchValue) {
      this.search = searchValue;
    },
  },
};
</script>

<style lang="scss">
#frame-container {
  padding: 40px 8em 0px 8em;
}
.sub-nav-action {
  position: fixed;
  right: 50px;
  bottom: 95px;
}
.btn-action {
  width: 25%;
  background-color: #2196f3 !important;
  text-decoration: none;
}

@media only screen and (max-width: 960px) {
  #frame-container {
    padding: 30px 0 0 0;
  }
}
</style>
