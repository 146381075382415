var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0",attrs:{"fluid":"","id":"frame-container"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cards.data,"loading":_vm.loading,"server-items-length":_vm.totalItems,"options":_vm.options,"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tasks",fn:function(ref){
var item = ref.item;
return _vm._l((item.tasks),function(task){return _c('div',{key:task.id},[_vm._v(" "+_vm._s(task.name)+" ")])})}},{key:"item.cardType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cardType.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? 'Active' : 'Inactive')+" ")]}},{key:"top",fn:function(){return [_c('search-bar',{on:{"searchCard":_vm.filteredCards}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":("/edit/" + (item.id)),"text":"","fab":""}},[_c('v-icon',{attrs:{"md":""}},[_vm._v(" mdi-circle-edit-outline ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getCards}},[_vm._v("Reset")])]},proxy:true}])}),_c('div',{staticClass:"sub-nav-action text-center"},[_c('v-btn',{attrs:{"color":"#2196f3","fab":"","height":"50px","width":"50px","to":{name: 'create-card'}}},[_c('v-icon',{attrs:{"color":"white","size":"24px"}},[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }