<template>
  <v-row>
    <v-col cols="12" sm="7" md="5" class="pt-0">
      <v-text-field
        class="search-input rounded-0"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search..."
        single-line
        solo
        hide-details
        background-color="#42a5f5"
        dark
        flat
        clearable
        @click:clear="searchCard('')"
        @keyup="searchCard($event.target.value)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'SearchFilter',
  methods: {
    searchCard: _debounce(
      function emitKeys(value) {
        this.$emit('searchCard', value);
      }, 500,
    ),
  },
};
</script>
